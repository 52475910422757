import { CircularProgress } from "@material-ui/core";
import React from "react";

const Reload = () => {
  return (
    <div className="modal">
      <CircularProgress style={{ color: "#FCE34D" }} />
    </div>
  );
};
export default Reload;
