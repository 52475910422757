import { Question } from "../../api";

let initialState = {
  question: [],
};

const questionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_QUESTIONS": {
      return {
        ...state,
        question: action.data,
      };
    }

    default:
      return state;
  }
};

export const setQuestion = (data) => ({ type: "GET_QUESTIONS", data });

export const getQuestion = () => {
  return (dispath) => {
    Question.getQuestion().then((response) => dispath(setQuestion(response)));
  };
};

export default questionReducer;
