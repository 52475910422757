import { Operations } from "../../api";

let initialState = {
  OperationsHistoryList: [],
  OperationsHistoryLoading: false,
  operationsTypes: [],
};

const OperationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_OPERATIONS_LIST": {
      return {
        ...state,
        OperationsHistoryList: action.data,
        OperationsHistoryLoading: true,
      };
    }

    case "SET_OPERATIONS_TYPES": {
      return {
        ...state,
        operationsTypes: action.data,
        OperationsHistoryLoading: true,
      };
    }

    default:
      return state;
  }
};
export const setOperationsHistory = (data) => ({
  type: "SET_OPERATIONS_LIST",
  data,
});

export const setOperationsTypes = (data) => ({
  type: "SET_OPERATIONS_TYPES",
  data,
});

export const getOperationList = (data) => {
  return (dispath) => {
    Operations.getOperationsHistory(data).then((response) =>
      dispath(setOperationsHistory(response))
    );
  };
};

export const getOperationsTypes = () => {
  return (dispath) => {
    Operations.getOperationsTypes().then((response) =>
      dispath(setOperationsTypes(response))
    );
  };
};

export default OperationsReducer;
