import { Orders } from "../../api";

let initialState = {
  order_params: {},
  order_list: {},
  order_list_executed: {},
  order_data: {
    open: false,
    data: null,
    severity: null,
  },
};

const orders = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ORDER_PARAMS": {
      return {
        ...state,
        order_params: action.data,
      };
    }

    case "SET_ORDER_DATA": {
      return {
        ...state,
        order_data: action.data,
      };
    }

    case "SET_ORDER_LISSST": {
      return {
        ...state,
        order_list: action.data,
      };
    }

    case "SET_ORDER_LISSST_EXECUTED": {
      return {
        ...state,
        order_list_executed: action.data,
      };
    }

    default:
      return state;
  }
};
export const setOrderParams = (data) => ({ type: "GET_ORDER_PARAMS", data });
export const setOrderdata = (data) => ({ type: "SET_ORDER_DATA", data });
export const setOrderList = (data) => ({ type: "SET_ORDER_LISSST", data });
export const setOrderListExecuted = (data) => ({
  type: "SET_ORDER_LISSST_EXECUTED",
  data,
});

export const getOrderParameters = () => {
  return (dispath) => {
    Orders.getOrderParameters().then((response) =>
      dispath(setOrderParams(response))
    );
  };
};

export const createOrder = (data) => {
  return (dispath) => {
    Orders.createOrder(data).then((response) =>
      dispath(
        setOrderdata({
          open: true,
          data: response.data,
          severity: response.status,
        })
      )
    );
  };
};

export const getOrderList = () => (dispath) =>
  Orders.getOrderList().then((response) => dispath(setOrderList(response)));

export const getOrderListExecuted = () => (dispath) =>
  Orders.getOrderListExecuted().then((response) =>
    dispath(setOrderListExecuted(response))
  );

export const cancelOrder = (id) => (dispath) =>
  Orders.cancelOrder(id).then((response) =>
    dispath(
      setOrderdata({
        open: true,
        data: response.data,
        severity: response.status,
      })
    )
  );

export default orders;
