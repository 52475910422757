import axios from "axios";
import cookie from "cookie_js";

function get_token() {
  return cookie.get("token");
}

export const istance = axios.create({
  baseURL: `https://test.netex.kg/${localStorage.getItem("i18nextLng")}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=UTF-8",
    Authorization: `Bearer ${get_token()}`,
  },
});

export const istanceSecond = axios.create({
  baseURL: `https://ns1.netex.kg/`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=UTF-8",
    Authorization: `Bearer ${get_token()}`,
  },
});

// auth
export const Register = {
  postRegister(register) {
    return istance
      .post("/auth/registration", register)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
};

export const Login = {
  postLogin(login) {
    return istance
      .post("/auth/login", login)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
};

export const ActivationAccount = {
  postActivationAccount(ActivationAccountEmail) {
    return istance
      .post("/auth/activation", ActivationAccountEmail)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  resendActivationAccountCode(data) {
    return istance
      .post("/auth/activation/resend", data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
};

export const Forgot = {
  postforgot(forgotData) {
    return istance
      .post("/auth/forgot", forgotData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
};

export const Main = {
  getMain() {
    return istance
      .get("index")
      .then((res) => res.data)
      .catch((e) => e.response);
  },
};

export const Сurrencies = {
  getСurrencies() {
    return istance
      .get("currencies")
      .then((res) => res.data)
      .catch((e) => e.response);
  },
  getСurrenciesFiat() {
    return istance
      .get("currencies/fiat")
      .then((res) => res.data)
      .catch((e) => e.response);
  },
};

export const Operations = {
  getOperationsHistory(data) {
    return istance
      .post("operations", data)
      .then((res) => res.data)
      .catch((e) => e.response);
  },

  getOperationsTypes() {
    return istance
      .post("operations/list")
      .then((res) => res)
      .catch((e) => e.response);
  },
};

export const News = {
  getNews(id) {
    return istance
      .get(`news`)
      .then((res) => res.data)
      .catch((e) => e.response);
  },
  getNewsById(id) {
    return istance
      .get(`news/${id}`)
      .then((res) => res.data)
      .catch((e) => e.response);
  },
};

export const Question = {
  getQuestion() {
    return istance
      .get("faq")
      .then((res) => res.data)
      .catch((e) => e.response);
  },
};

export const Profile = {
  getBalances() {
    return istance
      .get("/currencies/personal")
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  },

  postImgAvatar(avatar) {
    return istance
      .post("/profile/personal/edit", avatar)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },

  removeImgAvatar() {
    return istance
      .post("/profile/personal/remove-photo")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },

  getProfile() {
    return istance
      .get("/profile/personal")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  editProfile(data) {
    return istance
      .post("/profile/personal/edit-profile", data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },

  getProfileBalance() {
    return istance
      .get("/currencies")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },

  getProfileActionLog(page) {
    return istance
      .get("/profile/action-log/")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },

  changePassword(newPassword) {
    return istance
      .post("/profile/password", newPassword)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },

  verificationProfile(data) {
    return istance
      .post("/profile/verification", data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  getSecurityParametrs() {
    return istance
      .post("/profile/security/parameters")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },

  editSecurityParameters(data) {
    return istance
      .post("/profile/security/2fa/edit", data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
};

export const Replenishment = {
  getReplenishmentCategories() {
    return istance
      .get("replenish/categories")
      .then((res) => res)
      .catch((e) => e.response);
  },

  getReplenishmentMethods(id) {
    return istance
      .get(`replenish/methods/${id}`)
      .then((res) => res)
      .catch((e) => e.response);
  },

  postReplenishmentData(slug, data) {
    return istance
      .post(`replenish/${slug}`, data)
      .then((res) => res)
      .catch((e) => e.response);
  },
};

export const Withdraw = {
  getWithDrawParameters(coin) {
    return istance
      .get(`cashout/parameters/${coin}`)
      .then((res) => res)
      .catch((e) => e.response);
  },

  createWithDraw(data) {
    return istance
      .post(`cashout/create`, data)
      .then((res) => res)
      .catch((e) => e.response);
  },

  confirmWithDraw(id, data) {
    return istance
      .post(`/cashout/confirm/${id}`, data)
      .then((res) => res)
      .catch((e) => e.response);
  },
};

export const Sell = {
  getSellByParameters(parameter) {
    return istance
      .get(`sell/parameters/${parameter}`)
      .then((res) => res.data)
      .catch((e) => e.response);
  },
  SellCreate(data) {
    return istance
      .post("sell/create", data)
      .then((res) => res)
      .catch((e) => e.response);
  },
  SellConfirm(id) {
    return istance
      .get(`sell/confirm/${id}`)
      .then((res) => res)
      .catch((e) => e.response);
  },
};

export const Buy = {
  getBuyByParameters(parameter) {
    return istance
      .get(`buy/parameters/${parameter}`)
      .then((res) => res.data)
      .catch((e) => e.response);
  },
  BuyCreate(data) {
    return istance
      .post("buy/create", data)
      .then((res) => res)
      .catch((e) => e.response);
  },
  BuyConfirm(id) {
    return istance
      .get(`buy/confirm/${id}`)
      .then((res) => res)
      .catch((e) => e.response);
  },
};

export const Orders = {
  getOrderParameters() {
    return istance
      .get(`order/parameters`)
      .then((res) => res.data)
      .catch((e) => e.response);
  },

  createOrder(data) {
    return istance
      .post(`order/new`, data)
      .then((res) => res)
      .catch((e) => e.response);
  },

  getOrderList() {
    return istance
      .post(`order/list`)
      .then((res) => res.data)
      .catch((e) => e.response);
  },
  getOrderListExecuted() {
    return istance
      .post(`order/list/executed`)
      .then((res) => res.data)
      .catch((e) => e.response);
  },

  cancelOrder(id) {
    return istance
      .post(`order/cancel/${id}`)
      .then((res) => res)
      .catch((e) => e.response);
  },
};

export const Countries = {
  getCountries() {
    return istance
      .get(`countries`)
      .then((res) => res.data)
      .catch((e) => e.response);
  },
};

export const Reviews = {
  getReviews() {
    return istance
      .get(`reviews`)
      .then((res) => res.data)
      .catch((e) => e.response);
  },

  postReview(data) {
    return istance
      .post(`reviews/add`, data)
      .then((res) => res)
      .catch((e) => e.response);
  },
};

export const Internal = {
  createInternal(data) {
    return istanceSecond
      .post(`/internal/create/`, data)
      .then((res) => res)
      .catch((e) => e.response);
  },

  getInternalList() {
    return istanceSecond
      .get(`internal/get/list?me=1`)
      .then((res) => res)
      .catch((e) => e.response);
  },

  acceptInternal(id, code) {
    return istanceSecond
      .post(`/internal/accept/${id}/`, code)
      .then((res) => res)
      .catch((e) => e.response);
  },

  getInternalQuantity() {
    return istanceSecond
      .get(`/internal/quantity/`)
      .then((res) => res.data)
      .catch((e) => e.response);
  },

  getInternalCommission() {
    return istanceSecond
      .get(`/internal/commission/`)
      .then((res) => res.data)
      .catch((e) => e.response);
  },
};

// p2p
export const P2P = {
  createDeals(data) {
    return istanceSecond
      .post(`create/`, data)
      .then((res) => res)
      .catch((e) => e.response);
  },
  deleteDeal(id) {
    return istanceSecond
      .delete(`retrieve_update_delete/${id}/`)
      .then((res) => res)
      .catch((e) => e.response);
  },
  listDeals() {
    return istanceSecond
      .get(`/`)
      .then((res) => res.data)
      .catch((e) => e.response);
  },
  dealById(id) {
    return istanceSecond
      .get(`/retrieve_update_delete/${id}/`)
      .then((res) => res.data)
      .catch((e) => e.response);
  },
  joinToDeal(id, data) {
    return istanceSecond
      .put(`/join/${id}/`, data)
      .then((res) => res.data)
      .catch((e) => e.response);
  },

  acceptPaymentSent(id, data, type) {
    return istanceSecond
      .put(
        `/accept_payment${type === "card2" ? "_card2" : ""}/sent/${id}/`,
        data
      )
      .then((res) => res)
      .catch((e) => e.response);
  },
  acceptPaymentGet(id, data, type) {
    return istanceSecond
      .put(
        `/accept_payment${type === "card2" ? "_card2" : ""}/get/${id}/`,
        data
      )
      .then((res) => res)
      .catch((e) => e.response);
  },

  acceptCash(id, acceptType) {
    return istanceSecond
      .put(`/trade/${acceptType === "cash2" ? "accept2" : "accept"}/${id}/`)
      .then((res) => res)
      .catch((e) => e.response);
  },

  myDeals() {
    return istanceSecond
      .get(`/my_trades/`)
      .then((res) => res.data)
      .catch((e) => e.response);
  },
  commission() {
    return istanceSecond
      .get(`/commission/`)
      .then((res) => res.data)
      .catch((e) => e.response);
  },
};

export const Pay24 = {
  getCategories() {
    return istanceSecond
      .get(`/pay24/category/list/`)
      .then((res) => res.data)
      .catch((e) => e.response);
  },
  getByIdService(id) {
    return istanceSecond
      .get(`/pay24/service/list/?category=${id}`)
      .then((res) => res.data)
      .catch((e) => e.response);
  },
  getByIdServiceDetail(id) {
    return istanceSecond
      .get(`/pay24/service/get/${id}/`)
      .then((res) => res.data)
      .catch((e) => e.response);
  },

  getCheck(data) {
    return istanceSecond
      .post(`/pay24/check_requisite/`, data)
      .then((res) => res)
      .catch((e) => e.response);
  },

  payPayment(data) {
    return istanceSecond
      .post(`pay24/pay/${data}/`)
      .then((res) => res)
      .catch((e) => e.response);
  },
};

export const f2a = {
  generateCode() {
    return istance
      .get(`/profile/security/2fa/generate/`)
      .then((res) => res.data)
      .catch((e) => e.response);
  },

  enable2fa(data) {
    return istance
      .post(`/profile/security/2fa/enable/`, data)
      .then((res) => res)
      .catch((e) => e.response);
  },

  disable2fa(data) {
    return istance
      .post(`/profile/security/2fa/disable/`, data)
      .then((res) => res)
      .catch((e) => e.response);
  },
};

export const BinancePay = {
  getQrCode(id) {
    return istanceSecond
      .post(`/binance-pay/get_qr/${id}`)
      .then((res) => res.data)
      .catch((e) => e.response);
  },

  getPaymentDetails(id) {
    return istanceSecond
      .get(`/pay24/get/${id}/`)
      .then((res) => res.data)
      .catch((e) => e.response);
  },
};
