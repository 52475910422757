import { Main } from "../../api";

let initialState = {
  MainList: [],
  isDrawerOpen: false,
  isDrawerLanguageOpen: false,
  balance: 0,
};

const MainReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_MAIN_PAGE": {
      return {
        ...state,
        MainList: action.data,
      };
    }

    case "DRAWER_OPEN": {
      return {
        ...state,
        isDrawerOpen: action.data,
      };
    }

    case "DRAWER_LANGUAGE_OPEN": {
      return {
        ...state,
        isDrawerLanguageOpen: action.data,
      };
    }

    case "SET_BALANCE_GLOBAL": {
      return {
        ...state,
        balance: action.data,
      };
    }

    default:
      return state;
  }
};
export const setMainPage = (data) => ({ type: "SET_MAIN_PAGE", data });
export const setDrawerOpen = (data) => ({ type: "DRAWER_OPEN", data });
export const setBalanceGlobal = (data) => ({
  type: "SET_BALANCE_GLOBAL",
  data,
});
export const setDrawerLanguageOpen = (data) => ({
  type: "DRAWER_LANGUAGE_OPEN",
  data,
});

export const getMainList = () => {
  return (dispath) => {
    Main.getMain().then((response) => dispath(setMainPage(response)));
  };
};
export default MainReducer;
