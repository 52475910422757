import { Сurrencies } from "../../api";

let initialState = {
  СurrenciesList: [],
  СurrenciesLoading: false,
  СurrenciesListFiat: []
};

const СurrenciesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_СURRENCIES_PAGE": {
      return {
        ...state,
        СurrenciesList: Object.values(typeof action?.data?.currencies === "object" && action?.data?.currencies).sort((a, b) => b.balance - a.balance),
        СurrenciesLoading: true,
      };
    }
    case "SET_СURRENCIES_FIAT": {
      return {
        ...state,
        СurrenciesListFiat: action.data,
      };
    }
    default:
      return state;
  }
};
export const setСurrenciesPage = (data) => ({
  type: "SET_СURRENCIES_PAGE",
  data,
});
export const setСurrenciesFiat = (data) => ({
  type: "SET_СURRENCIES_FIAT",
  data,
});

export const getСurrenciesList = () => {
  return (dispath) => {
    Сurrencies
      .getСurrencies()
      .then((response) => dispath(setСurrenciesPage(response)));
  };
};

export const getСurrenciesListFiat = () => {
  return (dispath) => {
    Сurrencies
      .getСurrenciesFiat()
      .then((response) => dispath(setСurrenciesFiat(response)));
  };
};
export default СurrenciesReducer;
