import { createTheme } from "@mui/material";
import { createBreakpoints } from "@mui/system";

const breakpoints = createBreakpoints({});

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1440,
    },
  },

  typography: {
    lineHeight: 1,
    h1: {
      fontWeight: 700,
      fontSize: 72,
      lineHeight: "80px",
    },
    h2: {
      fontSize: 48,
      fontWeight: 500,
      lineHeight: "56px",
      [breakpoints.down(992)]: {
        fontSize: 34,
        lineHeight: "1!important",
      },
      [breakpoints.down(992)]: {
        fontSize: 34,
      },
    },
    h3: {
      fontSize: 39,
      fontWeight: "500",
      [breakpoints.down(992)]: {
        fontSize: 33,
      },
      [breakpoints.down(640)]: {
        fontSize: 20,
      },
    },
    h4: {
      fontSize: 28,
      fontWeight: "500",
      [breakpoints.down(640)]: {
        fontSize: 23,
      },
    },

    h5: {
      fontSize: 20,
      fontWeight: "500",
      lineHeight: "23px",
      [breakpoints.down(720)]: {
        fontSize: 16,
        fontWeight: "normal",
      },
    },
    h6: {
      fontSize: 19,
      lineHeight: "23px",
      [breakpoints.down(720)]: {
        fontSize: 16,
        lineHeight: "19px",
      },
    },
    body2: {
      fontSize: 16,
      lineHeight: "19px",
      fontWeight: "normal",

      [breakpoints.down(720)]: {
        fontSize: 16,
        lineHeight: "19px",
      },
    },

    body1: {
      fontSize: 18,
      fontWeight: "500",
      lineHeight: "normal",

      [breakpoints.down(576)]: {
        fontSize: 16,
      },
    },
  },

  palette: {
    primary: {
      light: "",
      main: "#FCE34D",
      dark: "",
      contrastText: "#000000",
    },

    secondary: {
      light: "",
      main: "#000",
      dark: "",
      contrastText: "#fff",
    },
  },

  overrides: {
    // MuiIconButton : {
    //   root: {
    //     padding: 0m
    //   }
    // },

    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: "none",
          padding: "2.5px 8px",
          minWidth: "unset",
          minHeight: "unset",
          borderSize: "2px",
          boxShadow: "none!important",

          "&.MuiButton-sizeLarge": {
            padding: "14px 28px",
            fontWeight: 500,
            fontSize: 18,
            lineHeight: "1",
            textTransform: "none",

            [breakpoints.down(992)]: {
              height: 56,
            },
          },

          "&.MuiButton-sizeMedium": {
            width: 201,
            height: 50,
            color: "red",
          },

          "&.Mui-disabled": {
            backgroundColor: "#E7EAEE!important",
            color: "#A0ABBB",
          },

          "&.MuiButton-outlined": {
            borderWidth: "2px",

            "&:hover": {
              borderWidth: "2px",
            },
          },

          "&.MuiButton-outlinedSecondary": {
            borderColor: "#000",
          },
        },
      },
    },

    MuiMenuItem: {
      root: {
        fontWeight: 300,
        "& img": {
          width: 30,
          height: 30,
          objectFit: "contain",
          marginRight: 20,
        },
      },
    },

    MuiFormControl: {
      root: {
        width: "100%",
        borderRadius: 8,

        "& img": {
          width: 30,
          height: 30,
          objectFit: "contain",
          marginRight: 20,
        },

        "& .MuiSelect-root": {
          height: "21px",
          display: "flex",
          alignItems: "center",
        },

        "& .MuiOutlinedInput-input": {
          borderRadius: 8,
          boxShadow: "0px 1px 0px #E2E8F0",
        },

        "& .MuiInputLabel-shrink": {
          transform: "translate(14px, -9px) scale(0.75)",
        },

        "& .MuiOutlinedInput-notchedOutline": {
          border: "1px solid transparent",
        },

        "& .MuiOutlinedInput-root.Mui-disabled": {
          borderColor: "transparent!important",
        },

        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "transparent!important",
          },

        "& .MuiInputLabel-formControl": {
          color: "#B8C0CC",
          fontWeight: 300,
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          maxWidth: "90%",
        },
      },
    },
  },
});
