import cookie from "cookie_js";

export const isAuthenticated = () => {
    const token = cookie.get('token')
    try {
      if (token) {
        return true;
      }
      else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }