import React, { useEffect } from "react";
import cookie from "cookie_js";
import { ThemeProvider as ThemeProviderOld } from "@material-ui/styles";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@material-ui/core";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PrivateRoute } from "./utils/PrivateRoute";
import { useDispatch } from "react-redux";
import { getBalances, getProfileData } from "./redux/reducers/myProfile";
import NotFound from "./pages/NotFound";
import { getInternalQuantity } from "./redux/reducers/internal";
import { theme } from "./theme";
import { getСurrenciesList } from "./redux/reducers/Сurrencies";

const Site = React.lazy(() => import("./Router/siteRoute"));
const AuthRoute = React.lazy(() => import("./Router/authRoute"));
const CabinetRoute = React.lazy(() => import("./Router/CabinetRoute"));

function App() {
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const dispatch = useDispatch();

  const token = cookie.get("token");

  useEffect(() => {
    dispatch(getСurrenciesList());
    if (token) {
      dispatch(getProfileData());
      dispatch(getBalances());
    }

    console.log("%c%s", "color: red; font-size: 20px", "WARNING");
    console.log(
      "%c%s",
      "font-size: 20px",
      "This browser feature is for developers. If someone told you to write here any data, they are scammers. If you paste data, you will give scammers access to your account."
    );

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getInternalQuantity());
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <ThemeProviderOld theme={oldTheme}>
      <ThemeProvider theme={theme}>
        <Switch>
          <Redirect exact from="/" to="/main/home" />
          <Route path="/main/" component={() => <Site t={t} i18n={i18n} />} />
          <Route
            path="/accounts/"
            component={() => <AuthRoute t={t} i18n={i18n} />}
          />
          <PrivateRoute
            path="/dashboard/"
            component={() => <CabinetRoute t={t} i18n={i18n} />}
          />
          <Route exact={true} path="*">
            <NotFound />
          </Route>
        </Switch>
      </ThemeProvider>
    </ThemeProviderOld>
  );
}

export default App;

const breakpoints = createBreakpoints({});

const oldTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1440,
    },
  },

  typography: {
    lineHeight: 1,
    h1: {
      fontWeight: 700,
      fontSize: 72,
      lineHeight: "80px",
    },
    h2: {
      fontSize: 48,
      fontWeight: 500,
      lineHeight: "56px",
      [breakpoints.down(992)]: {
        fontSize: 34,
        lineHeight: "1!important",
      },
      [breakpoints.down(992)]: {
        fontSize: 34,
      },
    },
    h3: {
      fontSize: 39,
      fontWeight: "500",
      [breakpoints.down(992)]: {
        fontSize: 33,
      },
      [breakpoints.down(640)]: {
        fontSize: 20,
      },
    },
    h4: {
      fontSize: 28,
      fontWeight: "500",
      [breakpoints.down(640)]: {
        fontSize: 23,
      },
    },

    h5: {
      fontSize: 20,
      fontWeight: "500",
      lineHeight: "23px",
      [breakpoints.down(720)]: {
        fontSize: 16,
        fontWeight: "normal",
      },
    },
    h6: {
      fontSize: 19,
      lineHeight: "23px",
      [breakpoints.down(720)]: {
        fontSize: 16,
        lineHeight: "19px",
      },
    },
    body2: {
      fontSize: 16,
      lineHeight: "19px",
      fontWeight: "normal",

      [breakpoints.down(720)]: {
        fontSize: 16,
        lineHeight: "19px",
      },
    },

    body1: {
      fontSize: 18,
      fontWeight: "500",
      lineHeight: "normal",

      [breakpoints.down(576)]: {
        fontSize: 16,
      },
    },
  },

  palette: {
    primary: {
      light: "",
      main: "#FCE34D",
      dark: "",
      contrastText: "#000000",
    },

    secondary: {
      light: "",
      main: "#000",
      dark: "",
      contrastText: "#fff",
    },
  },

  overrides: {
    // MuiIconButton : {
    //   root: {
    //     padding: 0m
    //   }
    // },

    MuiButton: {
      root: {
        borderRadius: 8,
        textTransform: "none",
        padding: "2.5px 8px",
        minWidth: "unset",
        minHeight: "unset",
        borderSize: "2px",
        boxShadow: "none!important",

        "&.MuiButton-sizeMedium": {
          width: 201,
          height: 50,
          color: "red",
        },

        "&.Mui-disabled": {
          backgroundColor: "#E7EAEE!important",
          color: "#A0ABBB",
        },

        "& .MuiButton-label": {
          fontWeight: 500,
          fontSize: 18,
          lineHeight: "1",
          textTransform: "none",
        },

        "&.MuiButton-sizeLarge": {
          padding: "14px 28px",

          [breakpoints.down(992)]: {
            height: 56,
          },
        },

        "&.MuiButton-outlined": {
          borderWidth: "2px",

          "&:hover": {
            borderWidth: "2px",
          },
        },

        "&.MuiButton-outlinedSecondary": {
          borderColor: "#000",
        },
      },
    },

    MuiMenuItem: {
      root: {
        fontWeight: 300,
        "& img": {
          width: 30,
          height: 30,
          objectFit: "contain",
          marginRight: 20,
        },
      },
    },

    MuiFormControl: {
      root: {
        width: "100%",
        borderRadius: 8,

        "& img": {
          width: 30,
          height: 30,
          objectFit: "contain",
          marginRight: 20,
        },

        "& .MuiSelect-root": {
          height: "21px",
          display: "flex",
          alignItems: "center",
        },

        "& .MuiOutlinedInput-input": {
          borderRadius: 8,
          boxShadow: "0px 1px 0px #E2E8F0",
        },

        "& .MuiInputLabel-shrink": {
          transform: "translate(14px, -9px) scale(0.75)",
        },

        "& .MuiOutlinedInput-notchedOutline": {
          border: "1px solid transparent",
        },

        "& .MuiOutlinedInput-root.Mui-disabled": {
          borderColor: "transparent!important",
        },

        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "transparent!important",
          },

        "& .MuiInputLabel-formControl": {
          color: "#B8C0CC",
          fontWeight: 300,
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          maxWidth: "90%",
        },
      },
    },
  },
});
