import React from "react";
import cookie from "cookie_js";
import { Container, Grid, makeStyles, Typography } from "@material-ui/core";
import error from "../assets/images/shib.gif";
import { Link } from "react-router-dom";

const NotFound = () => {
  const classes = useStyles();

  const token = cookie.get("token");

  return (
    <section className={`${classes.section} notFound`}>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            container
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h1" style={{ marginBottom: 16 }}>
              Ошибка 404
            </Typography>
            <Typography variant="h4">
              Страница, которую вы ищете, была перемещена, удалена,
              переименована или может никогда не существовала. Вы можете перейти
              на{' '}
              <Link
                to={!token ? "/" : "/dashboard/main"}
                className={classes.link}
              >
                Главную страницу
              </Link>
              , чтобы найти нужную Вам информацию
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            container
            alignItems="center"
            justifyContent="center"
          >
            <img src={error} alt="" />
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default NotFound;

const useStyles = makeStyles((theme) => ({
  section: {
    color: "#000",
    backgroundColor: "#121212",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    overflow: "hidden",
    height: "100vh",

    "& h1": {
      fontWeight: "800",
      textTransform: "uppercase",
      color: "#f00",
      textAlign: "center",
      marginBottom: 32,

      [theme.breakpoints.down(992)]: {
        fontSize: 50,
      },
    },

    "& h4": {
      fontWeight: "600",
      textTransform: "uppercase",
      color: "#D0D5DD",

      [theme.breakpoints.down(992)]: {
        fontSize: 24,
        textAlign: "center",
      },

      [theme.breakpoints.down(576)]: {
        fontSize: 20,
      },
    },
  },

  link: {
    color: "#FCE34D",
    whiteSpace: "nowrap",
    transition: "all 0.2s linear",
    borderBottom: "2px solid transparent",

    "&:hover": {
      borderBottom: "2px solid #FCE34D",
    },
  },
}));
